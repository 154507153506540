* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  height: 100%;
  font-family: Inter, sans-serif;
}
